/* Corresponding Style Sheet for Project 2 of Free Code Camp
  * Coded by Peter Cruckshank
  GitHub: github.com/PeterCr
*/

/* GLOBAL styling*/

body {
  font-family: "Roboto Slab", serif;
}

footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  background-color: #99ebff;
  font-family: "Roboto Slab", serif;
}

footer ul {
  list-style: none;
  word-wrap: normal;
  padding-left: 0;
}

footer button {
  width: 50px;
  height: 50px;
  border-radius: 55%;
  display: inline-block;
  margin-bottom: 25px;
}

/* Styling by ID */

#section1 {
  font-size: 1.66em;
  text-shadow: 2px 2px 1px black;
  background: url(../img/beachSunset.jpg) repeat;
  background-size: cover;
  color: #ffffff;
  padding-top: 50px;
  padding-bottom: 25px;
  margin: 0;
}

#section1 h6 {
  font-family: "Indie Flower", cursive;
  font-size: 1.1em;
}

#section1 button {
  padding: 8px;
  margin: 5px;
}

#section2 {
  background-color: black;
}

#section2 a {
  color: rgb(0, 119, 178);
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.528);
  text-decoration: underline;
}

#section2 a:visited {
  color: rgb(106, 17, 106);
}

#portfolioLinks {
  column-fill: auto;
  padding-top: 50px;
}

#section3 {
  padding-bottom: 50px;
}

#section3 .logoFont {
  font-size: 1.6em;
  font-family: "Indie Flower", cursive;
}

#bottomButtons {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
}

#wildresponse-img,
#magnolia-img,
#real-estate-img,
#dev-img {
  cursor: pointer;
}

/* Styling by class */
.nav {
  font-family: "Spectral", serif;
}

.navbar-brand {
  font-weight: bold;
  font-family: "Indie Flower", cursive;
  font-size: 24px !important;
}

.btn-group {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
  justify-content: space-between;
  padding-top: 25px;
}

.myPic {
  border-radius: 20px !important;
}

.card-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

/* Media Queries  */
/* Tables and smaller */
@media screen and (max-width: 780px) {
  .btn-group {
    display: flex;
    flex-direction: column;
  }

  .myPic {
    max-height: 450px !important;
    width: auto;
  }

  #section1 button {
    width: 40%;
  }

  #bottom-logo {
    height: 50%;
    width: 50%;
  }
}